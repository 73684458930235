import { usePDF } from 'react-to-pdf';

function StatsSummary({ players, games }) {
  const stats = ['goals', 'assists', 'saves'];
  const { toPDF, targetRef } = usePDF({ filename: 'stats_summary.pdf' });

  const calculateTotalStats = (playerId) => {
    return games.reduce((totalStats, game) => {
      const playerStats = game.playerStats.find(ps => ps.playerId === playerId);
      if (playerStats) {
        stats.forEach(stat => {
          totalStats[stat] += playerStats.stats[stat];
        });
      }
      return totalStats;
    }, { goals: 0, assists: 0, saves: 0 });
  };

  return (
    <div className="mb-8 p-6 rounded-lg bg-white dark:bg-gray-800 shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Player Stats Summary</h2>
        <button 
          onClick={toPDF}
          className="px-3 py-1 rounded bg-green-500 text-white hover:bg-green-600 transition-colors"
        >
          Export PDF
        </button>
      </div>
      <div className="overflow-x-auto" ref={targetRef}>
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left">Player</th>
              {stats.map(stat => (
                <th key={stat} className="text-center">{stat.charAt(0).toUpperCase() + stat.slice(1)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {players.map(player => {
              const totalStats = calculateTotalStats(player.id);
              return (
                <tr key={player.id}>
                  <td>{player.name}</td>
                  {stats.map(stat => (
                    <td key={stat} className="text-center">{totalStats[stat]}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StatsSummary;
