import React, { useState, useEffect } from 'react';
import { usePDF } from 'react-to-pdf';
import './App.css';
import GameStats from './components/GameStats';
import GameCreator from './components/GameCreator';
import PlayerRoster from './components/PlayerRoster';
import StatsSummary from './components/StatsSummary';

function App() {
  const [games, setGames] = useState(() => {
    const savedGames = localStorage.getItem('soccerGames');
    return savedGames ? JSON.parse(savedGames) : [];
  });

  const [players, setPlayers] = useState(() => {
    const savedPlayers = localStorage.getItem('soccerPlayers');
    return savedPlayers ? JSON.parse(savedPlayers) : [];
  });

  // Change the initial state to true for dark mode
  const [darkMode, setDarkMode] = useState(true);
  const { toPDF, targetRef } = usePDF({ filename: 'soccer_stats.pdf' });

  useEffect(() => {
    localStorage.setItem('soccerGames', JSON.stringify(games));
    localStorage.setItem('soccerPlayers', JSON.stringify(players));
  }, [games, players]);

  useEffect(() => {
    // Apply dark mode by default
    document.documentElement.classList.add('dark');
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const addGame = (gameName) => {
    const newGame = {
      name: gameName,
      id: Date.now(),
      playerStats: players.map(player => ({ playerId: player.id, stats: { goals: 0, assists: 0, saves: 0 } }))
    };
    setGames(prevGames => [...prevGames, newGame]);
  };

  const updateGameStats = (gameId, playerId, statName, value) => {
    setGames(prevGames => prevGames.map(game => 
      game.id === gameId 
        ? {
            ...game,
            playerStats: game.playerStats.map(playerStat => 
              playerStat.playerId === playerId
                ? { ...playerStat, stats: { ...playerStat.stats, [statName]: Math.max(0, playerStat.stats[statName] + value) } }
                : playerStat
            )
          }
        : game
    ));
  };

  const deleteGame = (gameId) => {
    setGames(prevGames => prevGames.filter(game => game.id !== gameId));
  };

  const addPlayer = (playerName) => {
    const newPlayer = { id: Date.now(), name: playerName };
    setPlayers(prevPlayers => [...prevPlayers, newPlayer]);
    setGames(prevGames => prevGames.map(game => ({
      ...game,
      playerStats: [...game.playerStats, { playerId: newPlayer.id, stats: { goals: 0, assists: 0, saves: 0 } }]
    })));
  };

  const deletePlayer = (playerId) => {
    setPlayers(prevPlayers => prevPlayers.filter(player => player.id !== playerId));
    setGames(prevGames => prevGames.map(game => ({
      ...game,
      playerStats: game.playerStats.filter(playerStat => playerStat.playerId !== playerId)
    })));
  };

  return (
    <div ref={targetRef} className={`min-h-screen p-4 sm:p-8 ${darkMode ? 'dark bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="max-w-full sm:max-w-3xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
          <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-0">Soccer Stat Tracker</h1>
          <div className="flex flex-wrap justify-center sm:justify-end gap-2">
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="px-3 py-1 sm:px-4 sm:py-2 rounded bg-blue-500 text-white hover:bg-blue-600 transition-colors text-sm sm:text-base"
            >
              {darkMode ? 'Light Mode' : 'Dark Mode'}
            </button>
            <button
              onClick={() => toPDF()}
              className="px-3 py-1 sm:px-4 sm:py-2 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-sm sm:text-base"
            >
              Export All to PDF
            </button>
          </div>
        </div>
        <PlayerRoster players={players} onAddPlayer={addPlayer} onDeletePlayer={deletePlayer} />
        <GameCreator onAddGame={addGame} />
        <StatsSummary players={players} games={games} />
        {games.length === 0 ? (
          <p className="text-center mt-8">No games added yet. Add a game to start tracking stats!</p>
        ) : (
          games.map(game => (
            <GameStats 
              key={game.id} 
              game={game} 
              players={players}
              onUpdateStats={updateGameStats} 
              onDeleteGame={deleteGame}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default App;
