import { usePDF } from 'react-to-pdf';

function GameStats({ game, players, onUpdateStats, onDeleteGame }) {
  const stats = ['goals', 'assists', 'saves'];
  const { toPDF, targetRef } = usePDF({ filename: `${game.name}_stats.pdf` });

  const handleDeleteGame = () => {
    if (window.confirm(`Are you sure you want to delete the game "${game.name}"? This action cannot be undone.`)) {
      onDeleteGame(game.id);
    }
  };

  return (
    <div className="mb-8 p-4 sm:p-6 rounded-lg bg-white dark:bg-gray-800 shadow-md">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-0">{game.name}</h2>
        <div className="flex gap-2">
          <button 
            onClick={toPDF}
            className="px-2 py-1 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-sm"
          >
            Export PDF
          </button>
          <button 
            onClick={handleDeleteGame}
            className="px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600 transition-colors text-sm"
          >
            Delete Game
          </button>
        </div>
      </div>
      <div className="overflow-x-auto" ref={targetRef}>
        <table className="w-full text-sm sm:text-base">
          <thead>
            <tr>
              <th className="text-left">Player</th>
              {stats.map(stat => (
                <th key={stat} className="text-center">{stat.charAt(0).toUpperCase() + stat.slice(1)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {game.playerStats.map(playerStat => {
              const player = players.find(p => p.id === playerStat.playerId);
              return (
                <tr key={playerStat.playerId}>
                  <td className="truncate max-w-[100px] sm:max-w-none">{player ? player.name : 'Unknown Player'}</td>
                  {stats.map(stat => (
                    <td key={stat} className="text-center">
                      <span className="mx-1 sm:mx-2">{playerStat.stats[stat]}</span>
                      <button 
                        onClick={() => onUpdateStats(game.id, playerStat.playerId, stat, 1)}
                        className="px-1 sm:px-2 py-1 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-xs sm:text-sm"
                      >
                        +
                      </button>
                      <button 
                        onClick={() => onUpdateStats(game.id, playerStat.playerId, stat, -1)}
                        className="ml-1 sm:ml-2 px-1 sm:px-2 py-1 rounded bg-yellow-500 text-white hover:bg-yellow-600 transition-colors text-xs sm:text-sm"
                      >
                        -
                      </button>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GameStats;
